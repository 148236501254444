import { NavLink, Form, Link, useSubmit } from '@remix-run/react'
import { useRef } from 'react'
import { Button } from '#app/components/ui/button.tsx'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuPortal,
	DropdownMenuTrigger,
} from '#app/components/ui/dropdown-menu.tsx'
import { Icon, type IconName } from '#app/components/ui/icon.tsx'
import { Logo } from '#app/root.tsx'
import { ThemeSwitch } from '#app/routes/resources+/theme-switch.tsx'
import { useOptionalUser, useUser } from '#app/utils/user.ts'
import { CommandPalette } from '../command-palette.tsx'

export function AppSidebar({
	mainNavItems,
	footerNavItems,
}: {
	mainNavItems: { name: string; href: string; icon: IconName; end?: boolean }[]
	footerNavItems?: {
		name: string
		href: string
		icon: IconName
		end?: boolean
	}[]
}) {
	const user = useOptionalUser()

	return (
		// <ClientOnly fallback={<span>Loading Sidebar</span>}>
		// 	{() => (
		// <div className="top-0 hidden h-dvh w-64 flex-col justify-between bg-muted/20 p-6 lg:fixed lg:flex">
		<div className="inset-y-0 left-0 w-64 flex-col justify-between border-r bg-muted/20 p-6 lg:fixed lg:flex print:hidden">
			<div className="flex flex-col gap-6">
				<Logo />
				<CommandPalette />

				<div className="flex flex-col gap-1">
					{mainNavItems.map((item) => (
						<NavLink
							key={item.name}
							to={item.href}
							className={({ isActive }) =>
								`flex items-center gap-2 rounded-md p-1 ring-ring hover:bg-accent focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring ${isActive ? 'bg-muted' : ''}`
							}
							end={item.end}
						>
							<Icon name={item.icon} size="xl" className="rounded-md p-1" />
							<span>{item.name}</span>
						</NavLink>
					))}
				</div>
			</div>
			<div>
				<div className="flex flex-col gap-1">
					{footerNavItems?.map((item) => (
						<NavLink
							key={item.name}
							to={item.href}
							className={({ isActive }) =>
								`flex items-center gap-2 rounded-md p-1 ring-ring hover:bg-accent focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring ${isActive ? 'bg-muted' : ''}`
							}
							end={item.end}
						>
							<Icon name={item.icon} size="xl" className="rounded-md p-1" />
							<span>{item.name}</span>
						</NavLink>
					))}
				</div>
				<br />
				<div className="flex items-center justify-between gap-2">
					<ThemeSwitch />
					{user ? (
						<UserDropdown />
					) : (
						<Button asChild variant="default" size="lg">
							<Link to="/login">Log In</Link>
						</Button>
					)}
				</div>
			</div>
		</div>
		// 	)}
		// </ClientOnly>
	)
}

export function UserDropdown() {
	const user = useUser()
	const submit = useSubmit()
	const formRef = useRef<HTMLFormElement>(null)

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button asChild variant="secondary" className="w-full">
					<Link
						to={`/users/${user.username}`}
						// this is for progressive enhancement
						onClick={(e) => e.preventDefault()}
						className="flex items-center gap-2"
					>
						{/* <img
							className="h-8 w-8 rounded-full object-cover"
							alt={user.name ?? user.username}
							src={getUserImgSrc(user.image?.id)}
						/> */}
						<span className="text-body-sm font-bold">
							{user.name ?? user.username}
						</span>
					</Link>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuPortal>
				<DropdownMenuContent sideOffset={8} align="end">
					<DropdownMenuItem asChild>
						{/* <Link prefetch="intent" to={`/users/${user.username}`}>
							<Icon size="md" name="avatar">
								Profile
							</Icon>
						</Link> */}
					</DropdownMenuItem>
					<DropdownMenuItem asChild>
						<Link prefetch="intent" to="/app">
							<Icon size="md" name="home">
								App
							</Icon>
						</Link>
					</DropdownMenuItem>
					{/* <DropdownMenuItem asChild>
						<Link prefetch="intent" to={`/users/${user.username}/notes`}>
							<Icon className="text-body-md" name="pencil-2">
								Notes
							</Icon>
						</Link>
					</DropdownMenuItem> */}
					<DropdownMenuItem
						asChild
						// this prevents the menu from closing before the form submission is completed
						onSelect={(event) => {
							event.preventDefault()
							submit(formRef.current)
						}}
					>
						<Form action="/logout" method="POST" ref={formRef}>
							<Icon size="md" name="exit">
								<button type="submit">Logout</button>
							</Icon>
						</Form>
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenuPortal>
		</DropdownMenu>
	)
}
