import { useNavigate } from '@remix-run/react'
import * as React from 'react'
import { ClientOnly } from 'remix-utils/client-only'
import {
	CommandDialog,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
	CommandSeparator,
	CommandShortcut,
} from './ui/command'
import { Icon } from './ui/icon.tsx'

export function CommandPalette() {
	const [open, setOpen] = React.useState(false)
	const navigate = useNavigate()

	React.useEffect(() => {
		const down = (e: KeyboardEvent) => {
			if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
				e.preventDefault()
				setOpen((open) => !open)
			}
		}

		document.addEventListener('keydown', down)
		return () => document.removeEventListener('keydown', down)
	}, [])

	return (
		<>
			<button
				className="rounded-md border p-2 ring-ring hover:underline focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring"
				type="button"
				onClick={(_) => {
					// e.preventDefault()
					setOpen(!open)
				}}
			>
				<p className="text-sm text-muted-foreground">
					Press{' '}
					<kbd className="pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground opacity-100">
						<span className="text-xs">⌘</span>K
					</kbd>
				</p>
			</button>
			<ClientOnly>
				{() => (
					<CommandDialog open={open} onOpenChange={setOpen}>
						<CommandInput placeholder="Type a command or search..." />
						<CommandList className="ring-ring [scrollbar-width:thin] focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring">
							<CommandEmpty>No results found.</CommandEmpty>
							<CommandGroup heading="General">
								<CommandItem
									onSelect={(_) => {
										navigate('/app')
										setOpen(false)
									}}
								>
									{/* <RocketIcon className="mr-2 h-4 w-4" /> */}
									<Icon name="home" size="sm">
										Home
									</Icon>
								</CommandItem>
								<CommandItem
									onSelect={(_) => {
										navigate('/app/patients')
										setOpen(false)
									}}
								>
									<Icon name="person" size="sm">
										Patients
									</Icon>
								</CommandItem>
								<CommandItem
									onSelect={(_) => {
										navigate('/app/appts')
										setOpen(false)
									}}
								>
									<Icon name="calendar" size="sm">
										Appointments
									</Icon>
								</CommandItem>
								<CommandItem
									onSelect={(_) => {
										navigate('/app/pharmacy')
										setOpen(false)
									}}
								>
									<Icon name="archive" size="sm">
										Pharmacy
									</Icon>
								</CommandItem>
								<CommandItem
									onSelect={(_) => {
										navigate('/app/pathology')
										setOpen(false)
									}}
								>
									<Icon name="blending-mode" size="sm">
										Pathology
									</Icon>
								</CommandItem>
								<CommandItem
									onSelect={(_) => {
										navigate('/app/patients/new')
										setOpen(false)
									}}
								>
									<Icon name="person" size="sm">
										Create Patient
									</Icon>
								</CommandItem>
								<CommandItem
									onSelect={(_) => {
										navigate('/app/appts/new')
										setOpen(false)
									}}
								>
									<Icon name="check" size="sm">
										Book Appointment
									</Icon>
								</CommandItem>
								<CommandItem
									onSelect={(_) => {
										navigate('/app/pharmacy/new')
										setOpen(false)
									}}
								>
									<Icon name="file-text" size="sm">
										Pharmacy Billing
									</Icon>
								</CommandItem>
								<CommandItem
									onSelect={(_) => {
										navigate('/app/pathology/new')
										setOpen(false)
									}}
								>
									<Icon name="dots-horizontal" size="sm">
										Pathology Billing
									</Icon>
								</CommandItem>
							</CommandGroup>
							<CommandSeparator />
							<CommandGroup heading="Settings">
								{/* <CommandItem>

									<span>Profile</span>
									<CommandShortcut>⌘P</CommandShortcut>
								</CommandItem> */}

								<CommandItem
									onSelect={(_) => {
										navigate('/app/settings')
										setOpen(false)
									}}
								>
									<Icon name="gear" size="sm">
										Settings
									</Icon>
								</CommandItem>
							</CommandGroup>
							<CommandSeparator />
							<CommandGroup heading="Coming Soon!">
								<CommandItem disabled>
									<span>Invoices</span>
								</CommandItem>
							</CommandGroup>
						</CommandList>
					</CommandDialog>
				)}
			</ClientOnly>
		</>
	)
}

{
	/* <CommandDialog open={open} onOpenChange={setOpen}>
						<CommandInput placeholder="Type a command or search..." />
						<CommandList className="ring-ring [scrollbar-width:thin] focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring">
							<CommandEmpty>No results found.</CommandEmpty>
							<CommandGroup heading="General">
								<CommandItem
									onSelect={(_) => {
										navigate('/app')
										setOpen(false)
									}}
								>
									<Icon name="home" size="sm">
										Home
									</Icon>
								</CommandItem>
							</CommandGroup>
							<CommandSeparator />
							<CommandGroup heading="Patients">
								<CommandItem
									onSelect={(_) => {
										navigate('/app/patients')
										setOpen(false)
									}}
								>
									<Icon name="person" size="sm">
										Patients
									</Icon>
								</CommandItem>
								<CommandItem
									onSelect={(_) => {
										navigate('/app/patients/new')
										setOpen(false)
									}}
								>
									<Icon name="person" size="sm">
										Create Patient
									</Icon>
								</CommandItem>
							</CommandGroup>
							<CommandSeparator />
							<CommandGroup heading="Appointments">
								<CommandItem
									onSelect={(_) => {
										navigate('/app/appts')
										setOpen(false)
									}}
								>
									<Icon name="calendar" size="sm">
										Appointments
									</Icon>
								</CommandItem>
								<CommandItem
									onSelect={(_) => {
										navigate('/app/appts/new')
										setOpen(false)
									}}
								>
									<Icon name="calendar" size="sm">
										Create Appointment
									</Icon>
								</CommandItem>
							</CommandGroup>
							<CommandSeparator />
							<CommandGroup heading="Settings">
								<CommandItem>

									<span>Profile</span>
									<CommandShortcut>⌘P</CommandShortcut>
								</CommandItem>

								<CommandItem>
									<span>Settings</span>
									<CommandShortcut>⌘S</CommandShortcut>
								</CommandItem>
							</CommandGroup>
							<CommandSeparator />
							<CommandGroup heading="Coming Soon!">
								<CommandItem disabled>
									<span>Invoices</span>
								</CommandItem>
							</CommandGroup>
						</CommandList>
					</CommandDialog> */
}
